import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { rounded } from 'src/styles/rounded'
import { grayscale, white, orange, green, red } from 'src/styles/colors'

import SearchWhite from '../../../assets/images/svg/bg-search-white.svg'

export const BoxTickets = styled.div`
  border: 1px solid ${grayscale['200']};
  box-sizing: border-box;
  border-radius: 16px;
  min-height: 286px;
  margin-bottom: 24px;

  @media ${device.tablet} {
    margin-left: 12px;
    margin-right: 12px;
    max-width: 335px;
    float: left;
  }

  .height-title {
    
    @media ${device.tablet} {
      max-height: 95px;
    }
  }

  .icon {
    height: 33px;
    width: 33px;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
`

export const TableTickets = styled.div`
  
  @media ${device.desktopLG} {

    &:hover {
      background: ${grayscale[100]};
    }

    div {
      padding: 26.5px 16px;
      display: flex;
      align-items: center;

      &:first-child {
        width: 290px;
      }
      &:nth-child(2) {
        width: 130px;
      }
      &:nth-child(3) {
        width: 114px;
      }
      &:nth-child(4) {
        width: 149px;
      }
      &:nth-child(5) {
        width: 124px;
        align-items: center;
        display: flex;
        justify-content: center;
      }
      &:nth-child(6) {
        width: 130px;
        align-items: center;
        display: flex;
        justify-content: center;
      }
      &:nth-child(7) {
        width: 85px;
        align-items: center;
        display: flex;
        justify-content: center;
      }

      button {
        border: 0;
        background: transparent;
      }
    }
  }

  @media ${device.desktopXXL} {
   
    div {
      &:first-child {
        width: 442px;
      }
    }
  }
`

export const Tag = styled.span`
  border-radius: 4px;

  &.pago {
    background: ${green[400]};
    color: ${white};
  }
  &.vencer {
    background: #FFC700;
    color: ${grayscale[500]};
  }
  &.vencido {
    background: ${red[500]};
    color: ${white};
  }
`

export const LimitPage = styled.div`
  button {
    align-items: center;
    background: ${white};
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    border: 0;
    display: flex;
    justify-content: center;
    width: 50px;
    height: 50px;
    position: relative;
    margin: 0 auto;
    outline: none;
  }
`

export const ButtonCards = styled.button`
  background: ${white};
  width: 86px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid ${orange.base};
  outline: none !important;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &.pdf {
    width: 103px;
  }
`

export const GridContent = styled.div`
  background: ${white};

  .bread-crumb  {
    span {
      color: ${orange.extra} !important;
      font-weight: 700;
      opacity: 1;
    }
    a {
      color: ${grayscale[500]};
    }
  }
`

export const ButtonFilter = styled.button`
  border-radius: ${rounded['6']};
  color: ${white};
  margin-top: 8px;
  margin-right: 5px;
  min-width: 66px;
  height: 40px;
  padding: 0 10px;
  position: relative;
  outline: none !important;
  text-transform: capitalize;
  border: 1px solid  ${grayscale['200']};
  z-index: 9;

  @media ${device.tablet} {
    margin-right: 8px;
    padding-left: 16px;
    padding-right: 16px;
  }

  &.white {
    background: ${white};
    color: ${grayscale['500']};
    border: 1px solid  ${grayscale['200']};

    &.active {
      background: ${grayscale['500']};
      color: ${white};
    }
  }

  &.close {
    width: 30px;
    height: 30px;
    min-width: 30px;
    padding: 0;
    margin-top: 13px;
    margin-right: 0;
    color: ${grayscale['500']};
    background: none;
  }
`

export const SearchButton = styled.button`
  height: 48px;
  background: ${orange.extra} url(${SearchWhite}) no-repeat center right 24px;
  max-width: 229px;
  padding-bottom: 9px;
`

export const Copy = styled.div`
  background: ${grayscale[500]};
  border-radius: 8px;
  min-height: 64px;
  width: 90%;
  position: fixed;
  top: 150px;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0 16px;
  z-index: 10;

  @media ${device.tablet} {
    top: 165px;
    width: 471px;
  }

  @media ${device.desktopLG} {
    top: 95px;
  }

  button {
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: center;
    padding: 0 16px;
    border-radius: 8px;
    font-size: 10px;
    line-height: 12px;

    @media ${device.tablet} {
      width: 66px;
    }
  }
`
