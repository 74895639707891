import React, { useState } from 'react'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { Modal } from 'src/components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'

import SegundaViaBoletoPJ from 'src/components/UI/Forms/SegundaViaBoletoPJ'

import { NotFound, SearchButton } from './style'

const MSG = () => {
  const domReady = useDomReady()
  const [ isModalOpen, setIsModalOpen ] = useState(false)

  const cardsModal = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <SegundaViaBoletoPJ closeModal={() => setIsModalOpen(false)} />
    </Modal>
  )

  return (
    <>
      {cardsModal}
      <div className='co-12 col-md-10 text-center mx-auto mt-md-4'>
        <NotFound className='d-flex mx-auto'>
          <OrangeDsIcon icon='not-found' size='MD' color='#B6B7BB' />
        </NotFound>
        <h2 className='fs-24 lh-30 text-grayscale--500'>Nenhum resultado encontrado</h2>
        <p className='fs-14 lh-17 text-grayscale--400 mb-4'>Não existem boletos com as características que você informou.</p>
        <SearchButton
          title='Pesquisar novamente'
          onClick={() => setIsModalOpen(true)}
          className='btn fs-14 fw-600 rounded-2 text-white text-none justify-content-start'
        >
          Pesquisar novamente
        </SearchButton>
      </div>
    </>
  )
}

export default MSG
