import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'

export default styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  
  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    padding: 0;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin-top: 32px;
    margin-bottom: 0;
    padding-left: 16px;
    padding-right: 16px;

    li {
      margin: 0 8px;

      &:first-child {
        margin-right: 16px;
      }

      &:last-child {
        margin-left: 16px;
      }

      button {
        height: 40px;
        width: 40px;
        color: ${grayscale[500]};
        border-radius: 50%;
        font-size: 18px;
        font-weight: 700;
        border: 1px solid  ${grayscale[200]};
        
        &.active {
          background-color: ${grayscale[500]};
          color: white;
          border: none;
        }
      }
    }
  }
`
