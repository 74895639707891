import styled from 'styled-components'
import { grayscale, orange } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

import SearchWhite from '../../../../assets/images/svg/bg-search-white.svg'

export const SearchButton = styled.button`
  height: 48px;
  background: ${orange.extra} url(${SearchWhite}) no-repeat center right 24px;
  padding-bottom: 9px;
  
  @media ${device.tablet} {
    max-width: 229px;
  }
`

export const NotFound = styled.div`
  height: 64px;
  width: 64px;
  background: ${grayscale[100]};
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin-bottom: 32px;
`
