/* eslint-disable @typescript-eslint/typedef */
import React from 'react'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import PaginationStyle from './style'

type PaginationProps = {
  numberPages: number;
  interval: number;
  limit: number;
  setOffset: Function;
  activePage: number;
  setActivePage: Function;
}

const Pagination = ({ numberPages, interval, limit, setOffset, activePage, setActivePage }: PaginationProps) => {
  const arrayNumbers = new Array(numberPages).fill(1).map((_, index: number) => index + 1)

  function handleClickArrow (direction: string) {
    const newActivePage = direction === 'right' ? activePage + 1 : activePage - 1
    setOffset(limit * (newActivePage - 1))
    setActivePage(newActivePage)
  }

  function handleClickNumber (activePage: number) {
    setOffset(limit * (activePage - 1))
    setActivePage(activePage)
  }

  return (
    <PaginationStyle>
      <ul>
        <li>
          { activePage - 1 > 0 && arrayNumbers.length > 0 &&
            <button className='arrows' onClick={() => handleClickArrow('left')} title='Voltar'>
              <OrangeDsIcon icon='chevron-left' size='MD' color='#07605B' />
            </button>
          }
        </li>
        {
          arrayNumbers.slice((activePage - 1) - interval, (activePage - 1)).map((item: number) => (
            <li key={item}>
              <button onClick={() => handleClickNumber(item)} className={`${activePage === item ? 'active' : ''}`}>
                {item}
              </button>
            </li>
          ))
        }
        {
          arrayNumbers.slice((activePage - 1), (activePage - 1) + (interval + 1)).map((item: number) => (
            <li key={item}>
              <button onClick={() => handleClickNumber(item)} className={`${activePage === item ? 'active' : ''}`}>
                {item}
              </button>
            </li>
          ))
        }
        <li>
          { activePage < numberPages &&
            <button className='arrows' onClick={() => handleClickArrow('right')} title='Avançar'>
              <OrangeDsIcon icon='chevron-right' size='MD' color='#07605B' />
            </button>
          }
        </li>
        {arrayNumbers.length > 4 &&
          <li>
            <span className='d-block pt-1'>Resultado: {activePage} - { Math.floor(interval + activePage)} de {numberPages}</span>
          </li>
          }
      </ul>
    </PaginationStyle>
  )
}

Pagination.defaultProps = {
  interval: 3,
}

export default Pagination
