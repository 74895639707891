/* eslint-disable no-console */
import React, { useState, useEffect, MouseEvent } from 'react'
import ScrollTo from 'src/components/ScrollTo'
import useWidth from 'src/hooks/window/useWidth'
import Pagination from '../Pagination/_index'
import MSG from '../msg/_index'
import { orange, gray } from 'src/styles/colors'
import axios from 'axios'

import { WIDTH_MD } from 'src/utils/breakpoints'
import Icon from 'src/components/UI/MarkdownIcon'
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { TicketsProps, TypeResultProps } from '../types'
import { BoxTickets, TableTickets, Tag, LimitPage, ButtonCards, Copy } from '../style'

const TotalTicketsCards = ({ type, activePage, setActivePage, offset, setOffset, limit, setLimit, ticketsData }: TypeResultProps) => {
  const windowWidth = useWidth(300)
  const [ isCopy, setIsCopy ] = useState(false)
  const limitMobile = 6

  const [ offsetMobile, setOffsetMobile ] = useState(limitMobile)

  let filterTotalTickets = ticketsData

  if (type.length > 0) {
    filterTotalTickets.sort((a: TicketsProps, b: TicketsProps) => (a.status > b.status)
    ? 1 : ((b.status > a.status) ? -1 : 0))

    filterTotalTickets = ticketsData.filter((totalTicket: TicketsProps) => {
      return type.includes(totalTicket.status)
    })
  }

  const MoreTickets = () => {
    setOffsetMobile(offsetMobile + limitMobile)
  }

  useEffect(() => {
    setOffsetMobile(limitMobile)
    setLimit(10)
  }, [ windowWidth ])

  const copyToClipBoard = (evt: MouseEvent<HTMLButtonElement>, item: string) => {
    evt.preventDefault()
    navigator.clipboard.writeText(item)
    setIsCopy(true)
  }

  const CloseCopy = () => {
    setIsCopy(false)
  }

  async function PDFGenerator (e: MouseEvent<HTMLButtonElement>, nosso_numero: string, carteira: string) {
    e.preventDefault()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }

    try {
      const response = await axios.get(`${process.env.SEGUNDA_VIA_BOLETO}/boleto/${nosso_numero}/${carteira}`, {
        headers: config.headers,
      })

      const byteCharacters = atob(response.data.boleto)
      const byteNumbers = new Array(byteCharacters.length)

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      const file = new Blob([ byteArray ], { type: 'application/pdf;base64' })
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      {isCopy &&
        <Copy className='d-flex align-items-center justify-content-start'>
          <div className='col-1 px-0'>
            <OrangeDsIcon icon='copy' size='MD' color='#ffffff' />
          </div>
          <div className='col-8 '>
            <p className='fs-10 lh-12 fs-md-12 lh-md-15 text-white fw-700 mb-0'>Código do boleto copiado com sucesso.</p>
          </div>
          <div className='col-3 pr-0'>
            <button
              onClick={CloseCopy}
              className='btn bg-orange--extra fw-700 text-white text-none float-md-right'
            >
              Fechar
            </button>
          </div>
        </Copy>
      }
      { windowWidth > WIDTH_MD && ticketsData.length > 0 &&
        <TableTickets className='d-flex border-bottom'>
          <div className='fs-12 lh-15 fw-700 pb-3'>Boleto</div>
          <div className='fs-12 lh-15 fw-700 pb-3'>Nosso número</div>
          <div className='fs-12 lh-15 fw-700 pb-3'>Vencimento</div>
          <div className='fs-12 lh-15 fw-700 pb-3'>Valor</div>
          <div className='fs-12 lh-15 fw-700 pb-3'>Status</div>
          <div className='fs-12 lh-15 fw-700 pb-3'>Linha digitável</div>
          <div className='fs-12 lh-15 fw-700 pb-3'>Visualizar</div>
        </TableTickets>
      }
      {filterTotalTickets.length > 0 && windowWidth <= WIDTH_MD
         ? filterTotalTickets.slice(0, offsetMobile).map((item: TicketsProps, index: number) => (
           <BoxTickets key={index} className='col-12 col-md-6 bg-white p-4'>
             <div className='row mx-0'>
               <div className='col-12 height-title px-0 border-bottom mb-2'>
                 <p className='fs-14 lh-17 fw-600 text-grayscale--500'>Boleto de capital de giro {item.numeroParcela} de {item.quantidadeParcelas}</p>
               </div>
               <div className='col-6 pt-2 pb-4 px-0'>
                 <span className='d-block fs-14 lh-17 text-grayscale--400'>Nosso número</span>
                 <span className='fs-14 lh-17 text-grayscale--500 fw-600 d-block mt-1'>
                   {item.nossoNumero}
                 </span>
               </div>
               <div className='col-6 pt-2 pb-4 px-0'>
                 <span className='d-block fs-14 lh-17 text-grayscale--400'>Vencimento</span>
                 <span className='fs-14 lh-17 text-grayscale--500 fw-600 d-block mt-1'>
                   {item.vencimento}
                 </span>
               </div>
               <div className='col-6 pb-4 px-0'>
                 <span className='d-block fs-14 lh-17 text-grayscale--400'>Valor</span>
                 <span className='fs-14 lh-17 text-grayscale--500 fw-600 d-block mt-1'>
                   {item.valor}
                 </span>
               </div>
               <div className='col-6 pb-4 px-0'>
                 <span className='d-block fs-14 lh-17 text-grayscale--400'>Status</span>
                 <Tag
                   className={`fs-10 lh-12 text-grayscale--500 fw-600 mt-1 py-1 px-2 
                  ${(item.status === 'PAGO' && 'pago') || (item.status === 'A VENCER' && 'vencer') || (item.status === 'VENCIDO' && 'vencido')}`}
                 >
                   {item.status}
                 </Tag>
               </div>
               {item.status !== 'PAGO' &&
                 <>
                   <div className='col-6 px-0'>
                     <span className='d-block fs-14 lh-17 text-grayscale--400 mb-2'>Linha digitável</span>
                     <ButtonCards
                       onClick={(evt: MouseEvent<HTMLButtonElement>) => copyToClipBoard(evt, item.linhaDigitavel)}
                       className='fs-10 lh-12 text-orange--extra fw-700'
                       title='Copiar linha digitável'
                       disabled={item.linhaDigitavel === ''}
                     >
                       <span className='mr-2'>Copiar</span>
                       <OrangeDsIcon icon='copy' size='SM' color={item.linhaDigitavel === '' ? gray[550] : orange.extra} />
                     </ButtonCards>
                   </div>
                   <div className='col-6 px-0'>
                     <span className='d-block fs-12 lh-16 text-grayscale--400 mb-2'>Arquivo PDF</span>
                     <ButtonCards
                       onClick={(e: MouseEvent<HTMLButtonElement>) => PDFGenerator(e, item.nossoNumero, item.carteira)}
                       className='fs-10 lh-12 text-orange--extra fw-700 pdf'
                       title='Visualizar'
                     >
                       <span className='mr-2'>Visualizar</span>
                       <OrangeDsIcon icon='pdf-file' size='SM' color='#ff7a00' />
                     </ButtonCards>
                   </div>
                 </>
              }
             </div>
           </BoxTickets>
        ))
        : windowWidth <= WIDTH_MD && <MSG />
      }
      {filterTotalTickets.length > 0 && windowWidth > WIDTH_MD
        ? filterTotalTickets.slice(offset, offset + limit).map((item: TicketsProps, index: number) => (
          <TableTickets key={index} className='d-flex border-bottom'>
            <div>
              <span className='fs-14 lh-17 fw-600 text-grayscale--500 mb-0'>
                Boleto de capital de giro {item.numeroParcela} de {item.quantidadeParcelas}
              </span>
            </div>
            <div>
              <span className='fs-14 lh-17 text-grayscale--500 fw-600'>
                {item.nossoNumero}
              </span>
            </div>
            <div>
              <span className='fs-14 lh-17 text-grayscale--500 fw-600 d-block'>
                {item.vencimento}
              </span>
            </div>
            <div>
              <span className='fs-14 lh-17 text-grayscale--500 fw-600 d-block'>
                {item.valor}
              </span>
            </div>
            <div>
              <Tag
                className={`fs-10 lh-12 text-grayscale--500 fw-600 py-1 px-2 
                  ${(item.status === 'PAGO' && 'pago') || (item.status === 'A VENCER' && 'vencer') || (item.status === 'VENCIDO' && 'vencido')}`}
              >
                {item.status}
              </Tag>
            </div>
            <div>
              {item.status !== 'PAGO' &&
                <button
                  onClick={(evt: MouseEvent<HTMLButtonElement>) => copyToClipBoard(evt, item.linhaDigitavel)}
                  title='Copiar linha digitável'
                  disabled={item.linhaDigitavel === ''}
                >
                  <OrangeDsIcon icon='copy' size='MD' color={item.linhaDigitavel === '' ? gray[550] : orange.extra} />
                </button>
                }
            </div>
            <div>
              {item.status !== 'PAGO' &&
                <button
                  title='Visualizar boleto'
                  onClick={(e: MouseEvent<HTMLButtonElement>) => PDFGenerator(e, item.nossoNumero, item.carteira)}
                >
                  <OrangeDsIcon icon='pdf-file' size='MD' color='#ff7a00' />
                </button>
              }
            </div>
          </TableTickets>
        ))
        : windowWidth > WIDTH_MD && <MSG />
      }
      { windowWidth <= WIDTH_MD
        ? (
          <LimitPage className='col-12 d-flex mt-lg-4'>
            {offsetMobile < filterTotalTickets.length
            ? (
              <button onClick={MoreTickets}>
                <Icon color='grayscale--500' width='24' height='24' icon='navigation/chevron-down' directory='v2' />
              </button>
            )
            : (
              filterTotalTickets.length > 4 &&
                <ScrollTo
                  to='#resultado-boleto'
                  styles='mx-auto'
                  section='Boleto Pessoa jurídica'
                  sectionName='dobra_01'
                  elementName='icon'
                >
                  <button onClick={MoreTickets}>
                    <Icon color='grayscale--500' width='24' height='24' icon='navigation/chevron-up' directory='v2' />
                  </button>
                </ScrollTo>
            )
          }
          </LimitPage>
        )
      : <Pagination
          activePage={activePage}
          setActivePage={setActivePage}
          numberPages={Math.ceil(filterTotalTickets.length / limit)}
          limit={limit}
          setOffset={setOffset}
        />
      }
    </>
  )
}

TotalTicketsCards.defaultProps = {
  type: '',
}

export default TotalTicketsCards
