/* eslint-disable no-console */
import React, { useState, MouseEvent, useEffect } from 'react'
import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import BreadCrumb from 'src/components/Breadcrumb'
import axios from 'axios'

import useWidth from 'src/hooks/window/useWidth'
import useDomReady from 'src/hooks/window/useDomReady'
import { WIDTH_MD } from 'src/utils/breakpoints'
import Loading from 'src/components/LoadingIndicator'

import SectionComponent from 'src/components/SectionComponent'
import CardsTickets from './CardsTickets/_index'
import SegundaViaBoletoPJ from 'src/components/UI/Forms/SegundaViaBoletoPJ'

import pageContext from './pageContext.json'

import { GridContent, ButtonFilter, SearchButton } from './style'
import { TicketsProps } from './types'

const TicketFilter = () => {
  const domReady = useDomReady()
  const windowWidth = useWidth()
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const [ isTickets, setIsTickets ] = useState<string[]>()
  const [ isActive, setIsActive ] = useState<number | undefined>()
  const [ ticketsData, setTicketsData ] = useState([])
  const [ offset, setOffset ] = useState(0)
  const [ limit, setLimit ] = useState(10)
  const [ activePage, setActivePage ] = useState(1)
  const [ loading, setLoading ] = useState(true)

  useEffect(() => {
    async function getResult () {
      const result = window.sessionStorage.getItem('result')
      if (result) {
        const params = window.atob(result).split(',')
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'x-api-key': params[2],
          },
        }

        try {
          const response = await axios.get(`${process.env.SEGUNDA_VIA_BOLETO}/${params[0].trim()}/${params[1].trim()}`, {
            headers: config.headers,
          })

          setTicketsData(response.data.boletos)

          window.sessionStorage.removeItem('result')
          setLoading(false)
        } catch (err) {
          console.error(err)
          setTicketsData([])
          setLoading(false)
        }
      } else if (ticketsData.length > 0) {
        setLoading(false)
        setTicketsData(ticketsData)
      } else {
        setLoading(false)
        setTicketsData([])
      }
    }

    getResult()
  }, [ isTickets ])

  const ButtonsTickets = ticketsData.map((item: TicketsProps) => item.status)
  const ticketsStatus = ButtonsTickets.filter((item: string, index: number) => ButtonsTickets.indexOf(item) === index)

  const filterTickets: string[] = []

  ticketsStatus.forEach((item: string) => {
    if (item === 'VENCIDO') {
      filterTickets.unshift(item)
    } else if ((item === 'A VENCER')) {
      filterTickets.push(item)
    } else {
      filterTickets.push(item)
    }
  })

  const cardsModal = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <SegundaViaBoletoPJ closeModal={() => setIsModalOpen(false)} customOrigin='Segunda Via boleto' />
    </Modal>
  )

  function handleClear () {
    window.sessionStorage.removeItem('result')
    setIsActive(undefined)
    setIsTickets([])
  }

  function handleClick (evt: MouseEvent<HTMLButtonElement>, index: number) {
    evt.preventDefault()
    setIsActive(index)
    setOffset(0)
    setActivePage(1)
    const elementButton = evt.target as HTMLButtonElement
    const typeButton = elementButton.innerText

    if (ticketsData.includes(typeButton)) {
      const filtered = ticketsData.filter((item: string) => item === typeButton)
      setIsTickets(filtered)
    } else {
      setIsTickets([
        ...ticketsData,
        typeButton,
      ])
    }
  }

  return (
    <>
      <Layout pageContext={pageContext}>
        {cardsModal}
        {
          loading ? (
            <Loading className='my-5' />
          ) : (
            <SectionComponent
              id='resultado-boleto'
              SectionStyles='align-items-start'
              RowStyles='justify-content-start'
            >
              <GridContent className='col-12 mb-4'>
                <BreadCrumb
                  sectionName='Boleto Pessoa jurídica'
                  type='Home' product='2ª via do boleto'
                  link='/segunda-via-boleto/pessoa-juridica/'
                  text='Boleto Pessoa jurídica'
                />
              </GridContent>
              <div className='col-12 col-md-8 mb-4 pr-md-0'>
                <h1 className='text-grayscale--500 fs-32 lh-40 fs-md-40 lh-md-50 mb-0 font-sora fw-600'>
                  Boleto Pessoa jurídica
                </h1>
              </div>

              {ticketsData.length > 0 &&
                <>
                  <div className='col-12 col-md-4 mt-2 mb-4 mt-md-0 text-md-right'>
                    <SearchButton
                      title='Pesquisar novamente'
                      onClick={() => setIsModalOpen(true)}
                      className='btn fs-14 fw-600 rounded-2 text-white text-none justify-content-start'
                    >
                      Pesquisar novamente
                    </SearchButton>
                  </div>
                  <div className='col-12'>
                    <p className='text-grayscale--500 fs-16 lh-22 mb-3'>
                      Encontramos os seguintes resultados para a sua busca.
                    </p>
                  </div>
                </>
            }

              <div className={`${windowWidth < WIDTH_MD ? 'col-10' : ''} d-flex px-0`}>
                {ticketsData.length > 0 && filterTickets.map((item: string, index: number) => (
                  <div className='ml-2 mb-5 mb-lg-4' key={index}>
                    <ButtonFilter
                      title={item}
                      onClick={(evt: MouseEvent<HTMLButtonElement>) => handleClick(evt, index)}
                      className={`fs-12 lh-15 white ${filterTickets.includes(item) && isActive === index ? 'active' : ''}`}
                    >
                      {item}
                    </ButtonFilter>
                  </div>
              ))}
              </div>

              <div className='col-2 pl-md-2 pr-md-0 text-right text-md-left'>
                {ticketsData.length > 0 &&
                  <div className='mb-5 mb-lg-4'>
                    { windowWidth < WIDTH_MD
                  ? (
                    <ButtonFilter
                      title='Limpar boletos'
                      onClick={() => handleClear()}
                      className='close fs-12 lh-15'
                    >
                      X
                    </ButtonFilter>
                    )
                  : (
                    <ButtonFilter
                      title='Limpar boletos'
                      onClick={() => handleClear()}
                      className='white fs-12 lh-15 text-uppercase'
                    >
                      Limpar
                    </ButtonFilter>
                    )
                  }
                  </div>
              }
              </div>

              <div className='col-12 px-md-0 mb-4'>
                <CardsTickets
                  type={isTickets}
                  ticketsData={ticketsData}
                  offset={offset}
                  setOffset={setOffset}
                  limit={limit}
                  setLimit={setLimit}
                  activePage={activePage}
                  setActivePage={setActivePage}
                />
              </div>
            </SectionComponent>
          )
        }
      </Layout>
    </>
  )
}

export default TicketFilter
